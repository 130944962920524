import { fetchUsersAPI, fetchUserAPI, procUserAPI } from '@/services/apis'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchUsers(ctx, queryParams) {
      try {
        return await this.$axios.get(fetchUsersAPI, { params: queryParams })
      } catch (error) {
        return error
      }
    },
    async fetchUser(ctx, { id }) {
      try {
        return await this.$axios.get(`${fetchUserAPI}/${id}`)
      } catch (error) {
        return error
      }
    },
    async procUser(ctx, params) {
      try {
        return await this.$axios.post(procUserAPI, params)
      } catch (error) {
        return error
      }
    },
  },
}
